import * as React from "react";
import styled from "styled-components";
import SEO from "../components/SEO";

// styles
const pageStyles = {
  color: "#232129",
  padding: "96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
};
const MainDiv = styled.div`
  max-width: 1024px;
  margin: 24px auto;
  padding: 64px;
`;
const P = styled.p`
  margin: 0 0 1.5rem;
  line-height: 1.5;
  font-size: 18px;
  font-weight: 500;
  color: #3d4d69;
`;
const H1 = styled.h1`
  margin: 0 0 1.5rem;
  font-family: "Futura PT", -apple-system, "BlinkMacSystemFont", "Segoe UI",
    "Roboto", "Helvetica Neue", "Arial", "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: bold;
  text-rendering: optimizeLegibility;
  line-height: 1.25;
  font-size: 42px;
  letter-spacing: 0.42px;
  text-transform: capitalize;
`;
const StyledH2 = styled.h2`
  margin: 0 0 1.5rem;
  text-rendering: optimizeLegibility;
  font-size: 32px;
  line-height: 1.25;
`;
const StyledLi = styled.li`
  margin: 0 0 1.5rem;
  line-height: 1.5;
  font-size: 18px;
`;
const StyledA = styled.a`
  color: ${(props) => (props.sameColor ? `#1f2939` : `#0000EE`)};
  text-decoration: none;
`;

// markup
const Privacy = ({ location }) => {
  return (
    <>
      <SEO
        title="Privacy Policy"
        description="This page contains all the policies related to the privacy of users’ activities and data. Read carefully before you use Receiptmakerly."
        pathname={location.pathname && location.pathname}
      />
      <MainDiv>
        <H1 id="Privacy-Policy-Statement">Privacy Policy Statement </H1>
        <P>Last Updated: 26 SEP 2022</P>

        <P>
          This Privacy Policy contains the information related to your data
          management by us once you use our products or visit the website.
        </P>
        <P>
          This policy also explains your preferences about how we use
          information about you. Your preferences include how you can object to
          specific uses of information about you, including how you can access
          and update certain information about you.{" "}
          <strong>
            If you disagree with this policy, you are requested not to use our
            Services or any part of our business.
          </strong>
        </P>
        <P>
          By accessing or using our website, you are giving consent to this
          privacy policy and accepting it. This policy may change from time to
          time (check Changes to Our Privacy Policy section). Your continued use
          of the website after we make changes is considered to accept our
          changes. Therefore, please check the Privacy Policy Statement with a
          regular interval for updates or changes we are bringing.
        </P>

        <StyledH2 id="Appscom-In-Short">Receipt Makerly in short</StyledH2>
        <P>
          Receipt Makerly is a premium{" "}
          <a href="https://receiptmakerly.com/">online receipt generator</a>{" "}
          with 50+ pre-made templates. Using this tool anyone can make receipts
          of different kinds using the templates within a very short time.
          Receipts can be downloaded in image and pdf format.
        </P>

        <StyledH2 id="What-Information-We-Gather-About-You">
          What information we gather about you
        </StyledH2>
        <P>
          When you purchase from our website or store, we collect the personal
          information you give us, including but not limited to like your name,
          email address and address, as part of the buying and selling process.
          We also collect the data once you ask for support or subscribe to our
          newsletters.
        </P>

        <StyledH2 id="How-We-Use-Your-Information-And-Data">
          How we use your information and data
        </StyledH2>
        <P>
          We use the collected data for various purposes:
          <ul>
            <li>To provide and maintain our Service</li>

            <li>To notify you about changes to our Service</li>

            <li>
              To allow you to participate in interactive features of our Service
              when you choose to do so
            </li>

            <li>To provide customer support</li>

            <li>
              To gather analysis or valuable information so that we can improve
              our Service
            </li>

            <li>To monitor the usage of our Service</li>

            <li>To detect, prevent and address technical issues</li>

            <li>
              To provide you with news, special offers and general information
              about other goods, services and events which we offer that are
              similar to those that you have already purchased or enquired about
              unless you have opted not to receive such information
            </li>
          </ul>
        </P>

        <StyledH2 id="How-We-Secure-and-Information">Transfer of Data</StyledH2>
        <P>
          Your information, including Personal Data, may be transferred to — and
          maintained on — computers located outside of your state, province,
          country or other governmental jurisdiction where the data protection
          laws may differ from those from your jurisdiction.
        </P>
        <P>
          We will take all steps reasonably necessary to ensure that your data
          is treated securely and in accordance with this Privacy Policy and no
          transfer of your Personal Data will take place to an organization or a
          country unless there are adequate controls in place including the
          security of your data and other personal information.
        </P>
        <P>
          Your consent to this Privacy Policy followed by your submission of
          such information represents your agreement to that transfer.
        </P>

        <StyledH2 id="access">Access to your data and information</StyledH2>
        <P>
          We can delete or amend the information associated with your account
          upon your request on a case by case basis. For this, please get in
          touch with us through our support mail at: support@receiptmakerly.com
        </P>

        <P>
          Please note that we may ask you to verify your identity before
          responding to such requests and entertaining such request will not be
          always guaranteed.
        </P>

        <StyledH2 id="Secure">
          How we secure and store information we collect about you
        </StyledH2>

        <P>
          User data refers to any information that is stored in our system as a
          result of using Receipt Makerly services. This information could be
          personal or non-personal. Customers, members, businesses, contacts,
          contractors, suppliers, and other non-personal information are saved
          in the Receipt Makerly system as a result of using our apps or
          services. The security of your data is important to us, but remember
          that no method of transmission over the Internet, or method of
          electronic storage is 100% secure. While we strive to use commercially
          acceptable means to protect your Personal Data, we cannot guarantee
          its absolute security.
        </P>

        <StyledH2 id="GDPR">
          Legal Basis for Processing Personal Data Under General Data Protection
          Regulation (GDPR)
        </StyledH2>

        <P>
          {" "}
          If you are from the European Economic Area (EEA), Receipt Makerly
          legal basis for collecting and using the personal information
          described in this Privacy Policy depends on the Personal Data we
          collect and the specific context in which we collect it.
        </P>

        <P>
          Receipt Makerly may process your Personal Data because:
          <ul>
            <li>We need to perform a contract with you</li>

            <li>You have given us permission to do so</li>

            <li>
              The processing is in our legitimate interests and it’s not
              overridden by your rights
            </li>

            <li>For payment processing purposes</li>

            <li>To comply with the law</li>
          </ul>
        </P>
        <P>
          Your Data Protection Rights Under General Data Protection Regulation
          (GDPR)
        </P>

        <P>
          If you are a resident of the European Economic Area (EEA), you have
          certain data protection rights. Receipt Makerly aims to take
          reasonable steps to allow you to correct, amend, delete, or limit the
          use of your Personal Data.
        </P>

        <P>
          If you wish to be informed what Personal Data we hold about you and if
          you want it to be removed from our systems, please contact us.
        </P>

        <P>
          In certain circumstances, you have the following data protection
          rights:
          <ul>
            <li>
              <strong>The right to access, update or to delete</strong> the
              information we have on you.
            </li>

            <li>
              <strong>The right of rectification.</strong> You have the right to
              have your information rectified if that information is inaccurate
              or incomplete.
            </li>

            <li>
              <strong>The right to object.</strong> You have the right to object
              to our processing of your Personal Data.
            </li>

            <li>
              <strong>The right of restriction.</strong> You have the right to
              request that we restrict the processing of your personal
              information.
            </li>

            <li>
              <strong>The right to withdraw consent.</strong> You also have the
              right to withdraw your consent at any time where Receipt Makerly
              relied on your consent to process your personal information.
            </li>
          </ul>
        </P>

        <P>
          Please note that we may ask you to verify your identity before
          responding to such requests..and entertaining such request will not be
          always guaranteed.
        </P>
        <StyledH2 id="Secure">
          Receipt Makerly does not sell user information with any third parties.
        </StyledH2>
        <P>
          However, Receipt Makerly reserves the right to use your non-personal
          and personal information on a case-by-case basis to allow us to tailor
          products and services to your specific needs, to help us organize and
          manage our relationship with you or your business, to conduct
          business, to provide you with customer and guest support, for
          marketing, sales, and promotion, and to perform functions that are
          described to you at the time of collection.
        </P>

        <StyledH2 id="Secure">Disclosure</StyledH2>

        <P>
          We may disclose your personal information if these are required by law
          to do so or if you violate our Terms of Service.
        </P>
        <P>
          Receipt Makerly may disclose your Data in the good faith belief that
          such action is necessary to:
          <ul>
            <li>To comply with a legal obligation.</li>

            <li>
              To protect and defend the rights or property of Receipt Makerly.
            </li>

            <li>
              To prevent or investigate possible wrongdoing in connection with
              the service.
            </li>

            <li>
              To protect the personal safety of users of the service or the
              public.
            </li>

            <li>To protect against legal liability.</li>
          </ul>
        </P>
        <StyledH2 id="Cookies">Cookies</StyledH2>

        <P>
          A cookie is a little piece of data supplied from a website and saved
          on a user's computer by their web browser while they are browsing.
          Cookies are a secure way for websites to remember information (such as
          items put to an online store's shopping cart) or to track a user's
          browsing behaviour (including clicking particular buttons, logging in,
          or recording which pages were visited in the past). We use the data in
          cookies to improve the site's user experience. If you view a certain
          deal, for example, we make sure that similar deals are not shown on
          your next visit to the site to improve your user experience.
        </P>

        <P>
          If you do not want our service to save information in cookies on your
          computer, you can easily disable cookies in your browser.
        </P>

        <StyledH2 id="service-providers">Service Providers</StyledH2>

        <P>
          We may employ third party companies and individuals to facilitate our
          Service (“Service Providers”), to provide the Service on our behalf,
          to perform Service-related services or to assist us in analyzing how
          our Service is used.
        </P>

        <P>
          These third parties have access to your Personal Data only to perform
          these tasks on our behalf and are obligated not to disclose or use it
          for any other purpose.
        </P>

        <StyledH2 id="Analytics">Analytics</StyledH2>

        <P>
          We may use third-party Service Providers to monitor and analyze the
          use of our Service.Example of such services are [ not limited to ]:
        </P>

        <P>
          <ul>Google Analytics:</ul>
        </P>
        <P>
          Google Analytics is a web analytics service offered by Google that
          tracks and reports website traffic. Google uses the data collected to
          track and monitor the use of our Service. This data is shared with
          other Google services. Google may use the collected data to
          contextualize and personalize the ads of its own advertising network.
          You can opt-out of having made your activity on the Service available
          to Google Analytics by installing the Google Analytics opt-out browser
          add-on. The add-on prevents the Google Analytics JavaScript (ga.js,
          analytics.js, and dc.js) from sharing information with Google
          Analytics about visits activity. For more information on the privacy
          practices of Google, please visit the{" "}
        </P>

        <P>
          <ul>Google Privacy & Terms web page: </ul>
          https://www.google.com/intl/en/policies/privacy/
        </P>
        <StyledH2 id="linkmanagement">Link Management</StyledH2>

        <P>
          You may be redirected away from our site if you click on a link on our
          website. We are not responsible for the privacy policies of
          third-party websites, and we recommend that you read their privacy
          policies before you browse their website.
        </P>

        <StyledH2 id="Children’s Privacy">Children’s Privacy</StyledH2>

        <P>
          Our Service does not address anyone under the age of 18 (“Children”).
        </P>

        <P>
          We do not knowingly collect personally identifiable information from
          anyone under the age of 18. If you are a parent or guardian and you
          are aware that your Children have provided us with Personal Data,
          please contact us. If we become aware that we have collected Personal
          Data from children without verification of parental consent, we take
          steps to remove that information from our servers.
        </P>
        <StyledH2
          id="Changes to Our Privacy Policy
"
        >
          Changes to Our Privacy Policy
        </StyledH2>
        <P>
          Receipt Makerly reserves the right to modify or bring changes to this
          privacy policy at any time. So please review and check it frequently.
          Changes and modifications will take effect immediately upon our
          posting on the website. If we make material changes to this policy, we
          might add the date when this privacy policy is updated and the end of
          this policy. It will help you to remain aware of what information we
          collect, how we use it, and under what circumstances, if any, we use
          and/or disclose it.
        </P>

        <P>
          Your information may be passed to the new owners if our business is
          sold or merged with another business so that we can continue to sell &
          provide service to you.
        </P>

        <StyledH2 id="Changes to Our Privacy Policy">
          Feedback, Questions and Contact
        </StyledH2>
        <P>
          We highly value our users' feedback. If you have any questions or
          feedback, feel free to reach us through: support@receiptmakerly.com
        </P>
      </MainDiv>
    </>
  );
};

export default Privacy;
